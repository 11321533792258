import { SortDescriptor } from '@progress/kendo-data-query'

export interface Category {
  category_type: string,
  created: string,
  id: number,
  is_active: number,
  name: string,
  parent_id: number,
  rank: number,
  updated: string,
}

export interface LightCategory {
  id: number,
  name: string,
  parentId: number,
}

interface SubCategoryDetails {
  category_id: number,
  category_name: string,
  sub_category_id: number,
  sub_category_name: string,
}

export interface SubCategoriesMapping {
  [key: number]: SubCategoryDetails
}

export enum SourceTypes {
  facebook = 'Facebook',
  native = 'Manual Entry',
  rss = 'RSS Feed',
  scraper = 'Scraper',
  twitter = 'Twitter',
  twitterList = 'Twitter List',
  youtube = 'YouTube',
}

export interface Source {
  category: string,
  category_ids: string,
  id: number,
  identifier: string,
  name: string,
  options: string,
  type: SourceTypes,
  sub_category: string,
  sub_category_ids: string,
}

export interface SourcesListComponentProps {
  data: Source[],
  onClearSourcesSelections: any,
  onSelectSource: any,
  selectedSources: Source[],
  setPage: any,
  setSort: any,
  skip?: number,
  sort?: SortDescriptor[],
  take?: number,
  total: number,
  totalSources: number,
}

export interface SourcesListState {
  isLoading: boolean,
  searchTerm: string,
  skip: number,
  sort: SortDescriptor[],
  sources: Source[],
  take: number,
}

export interface ContentLibraryPageComponentState {
  categories: Category[],
  isLoading: boolean,
  searchTerm: string,
  selectedCategories: string[],
  selectedSubCategories: string[],
  skip: number,
  sort: SortDescriptor[],
  sources: Source[],
  selectedSources: Source[],
  subCategories: Category[],
  subCategoriesMapping: SubCategoriesMapping,
  take: number,
}
