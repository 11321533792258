import { orderBy as kendoOrderBy } from '@progress/kendo-data-query'
import { GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'
import { FiltersComponent } from 'components/filters'
import { LibraryIcon } from 'components/icons/library-icon'
import { Loading } from 'components/loading'
import { RasaPageComponent } from 'components/rasa-page-component'
import { RasaPageComponentProps } from 'components/rasa-page-component/types'
import { SearchBarComponent } from 'components/search-bar'
import { Dataset } from 'generic/dataset'
import { isAuthenticated, shouldShowBackButton } from 'generic/utility'
import { orderBy as lodashOrderBy} from 'lodash'
import React from 'react'
import { CLEAR_SEARCH_TERM, CLEAR_SELECTED_CATEGORIES, GAEvents, Pages, REVIEW_CLICK, SEARCH_PAGE } from '../../rootConstants'
import { BackButtonComponent } from '../back-button'
import * as CommonConstants from '../constants'
import * as GlobalTypes from '../types'
import { sendGAEvent } from '../utils'
import './_styles.scss'
import * as Constants from './constants'
import { SourcesListComponent } from './sources-list'
import * as Types from './types'

export class ContentLibraryPageComponent
  extends RasaPageComponent<RasaPageComponentProps, Types.ContentLibraryPageComponentState> {
  constructor(props) {
    super(props)
    const store = this.props.store.getState()

    this.state = {
      categories: [],
      isDesktop: store.config.isDesktop,
      isLoading: true,
      maxSourcesReached: false,
      searchTerm: store.contentLibrary.searchTerm || '',
      selectedSources: [],
      selectedCategories: [],
      selectedSubCategories: [],
      senderPage: store.contentLibrary.senderPage || '',
      skip: 0,
      sort: [],
      sources: [],
      sourcesInfo: store.externalConfig.sourcesInfo,
      subCategories: [],
      subCategoriesMapping: {},
      take: 25,
    }
  }

  public componentDidMount = () => {
    Promise.all([
      this.getSources(),
      this.getCategories(),
      this.getSubCategoriesMapping(),
    ]).then(() => {
      this.initialize()
    })
  }

  public render() {
    return (
      <div className="rct-content-library-page">
        {shouldShowBackButton() && <div><BackButtonComponent onClick={this.goBack}/></div>}
        <div className="header-container">
          <div className="header-icon-container">
            <LibraryIcon />
          </div>
          <div className="header-txt-container">
            <div className="header-title">Content library</div>
            <div className="header-description">{Constants.CONTENT_LIBRARY_HEADER_DESCRIPTION}</div>
          </div>
        </div>
      { this.state.isLoading ?
        <Loading size="64"></Loading> :
        <div className="body-container">
          <div className="left-bar">
            <FiltersComponent
              categories={this.state.categories}
              onCategoryChanged={this.onCategoryChanged}
              onClearAll={this.clearSelectedCategories}
              onSubCategoryChanged={this.onSubCategoryChanged}
              selectedCategoryName={this.state.selectedCategories[0]}
              subCategories={this.state.subCategories}
              subCategoriesMapping={this.state.subCategoriesMapping}
              searchTerm={this.state.searchTerm}
              fullSources={this.state.sources}
              sources={this.dataToDisplay()}
            />
          </div>
            <div className="center">
            <SearchBarComponent onSubmit={this.searchSubmit}/>
            <div className="notifications-container">
              <SearchTermNotification
                onClose={this.clearSearchTerm}
                searchTerm={this.state.searchTerm}/>
              {this.state.maxSourcesReached &&
              <div className="max-sources-reached-notification">
                You've reached your limit of <b>{this.state.sourcesInfo.maxAllowedSources} sources. </b>
                Upgrade to unlock more
              </div>
              }
            </div>
            <SourcesListComponent
              data={this.dataToDisplay().slice(this.state.skip, this.state.skip + this.state.take)}
              onClearSourcesSelections={this.onClearSourcesSelections}
              onSelectSource={this.onSelectSource}
              onSelectionsClick={this.reviewClick}
              isAuthenticated={isAuthenticated(this.props.store)}
              isDesktop={this.state.isDesktop}
              skip={this.state.skip}
              selectedSources={this.state.selectedSources}
              setSort={this.setSort}
              setPage={this.setPage}
              sort={this.state.sort}
              take={this.state.take}
              total={this.dataToDisplay().length}
              totalSources={this.state.sources.length}
              type={'selection'}
            />
          </div>
        </div>
        }
      </div>
    )
  }

  private searchSubmit = (term: string) => {
    this.setState({ searchTerm: term })
    sendGAEvent(this.props.store.getState(), {action: GAEvents.Page3Search, label: term})
  }

  private getSources = () => {
    return new Dataset()
      .loadPublicDataset('sources', null)
      .then((response) => {
        this.setState({
          sources: response[0]
            .filter((s) => !!s.is_content_guide_visible)
            .map((s) => {
              s.name = s.name.trim()

              return s
            }),
        })
      })
  }

  private getSubCategoriesMapping = () => {
    return new Dataset()
      .loadPublicDataset('subCategoriesMapping', null)
      .then((response) => {
        const mapping = {}
        const subCategories = []
        response[0].forEach((row: any) => {
          mapping[row.sub_category_id] = row
          subCategories.push({
            parent_id: row.category_id,
            name: row.sub_category_name,
            id: row.sub_category_id,
          })
        })
        this.setState({
          subCategories,
          subCategoriesMapping: mapping,
        })
      })
  }

  private getCategories = () => {
    return new Dataset()
    .loadPublicDataset('categories', null)
    .then((response) => {
      const orderedCategories = lodashOrderBy(response[0], ['name'], 'asc')
      this.setState({
        categories: orderedCategories,
      })
    })
  }

  private initialize = () => {
    const externalSelectedCategory: string = this.props.store.getState().externalConfig.selectedCategory

    const {searchTerm, selectedCategory, selectedSources} = this.props.store.getState().contentLibrary
    const selectedCategories = externalSelectedCategory ? [ externalSelectedCategory ] :
      selectedCategory && selectedCategory.hasOwnProperty('name') ? [selectedCategory.name] : []

    this.setState({
      isLoading: false,
      maxSourcesReached: this.maxSourcesReached(selectedSources),
      searchTerm,
      selectedCategories,
      selectedSources,
    })
  }

  private dataToDisplay = (): GlobalTypes.Source[] => {
    // TODO: how to handle it based on Id? how about sub category with same name and different category id
    const filteredData: GlobalTypes.Source[] = this.state.selectedCategories.length
    ? this.state.sources
      .filter((s) => {
        if (s.category) {
          const categoryArray = s.category.split(CommonConstants.CATEGORY_SPLIT_CHAR)
          for (const category of categoryArray) {
            if (this.state.selectedCategories.includes(category)) {
              return true
            }
          }
        }
        return false
      })
      .filter((s) => {
        if (this.state.selectedSubCategories.length) {
          if (s.sub_category) {
            const subCategoryArray = s.sub_category.split(CommonConstants.CATEGORY_SPLIT_CHAR)
            for (const subCategory of subCategoryArray) {
              if (this.state.selectedSubCategories.includes(subCategory)) {
                return true
              }
            }
          }
          return false
        } else {
          return true
        }
      })
    : this.state.sources

    const searchTerm = this.state.searchTerm.toLowerCase()

    const searchedFilteredData = searchTerm
      ? filteredData.filter((s: any) =>
        (s.category || '').toLowerCase().includes(searchTerm) ||
        (s.sub_category || '').toLowerCase().includes(searchTerm) ||
        (s.name || '').toLowerCase().includes(searchTerm) ||
        (s.identifier || '').toLowerCase().includes(searchTerm))
      : filteredData

    return kendoOrderBy(searchedFilteredData, this.state.sort)
  }

  private setSort = (e: GridSortChangeEvent): void => {
    this.setState({
      sort: e.sort,
    })
  }

  private setPage = (e: GridPageChangeEvent): void => {
    this.setState({
      skip: e.page.skip,
      take: e.page.take,
    })
  }

  private onCategoryChanged = (categoryName: string, subCategories: []): void => {
    const newSelectedCategories = this.state.selectedCategories.includes(categoryName) ?
      this.state.selectedCategories.filter((c) => c !== categoryName) :
      [...this.state.selectedCategories].concat([categoryName])

    // only send GAEvent when adding the category
    if (newSelectedCategories.length > this.state.selectedCategories.length) {
      sendGAEvent(this.props.store.getState(), {
        action: GAEvents.Page3CategorySelect,
        label: categoryName,
      })
    }

    const newSelectedSubCategories = newSelectedCategories.length
    ? subCategories.map((subCategory: GlobalTypes.LightCategory) => subCategory.name)
    : []
    this.setState({
      selectedCategories: newSelectedCategories,
      selectedSubCategories: newSelectedSubCategories,
    })
  }

  private onSubCategoryChanged = (subCategoryName: string): void => {
    const newSelectedSubCategories = this.state.selectedSubCategories.includes(subCategoryName) ?
      this.state.selectedSubCategories.filter((c) => c !== subCategoryName) :
      [...this.state.selectedSubCategories].concat([subCategoryName])
    // only send GAEvent when adding the subcategory
    if (newSelectedSubCategories.length > this.state.selectedSubCategories.length) {
      sendGAEvent(this.props.store.getState(), {
        action: GAEvents.Page3SubCategorySelect,
        label: subCategoryName,
      })
    }
    this.setState({selectedSubCategories: newSelectedSubCategories})
  }

  private onSelectSource = (source: GlobalTypes.Source) => {
    const alreadySelected = this.state.selectedSources.some((s) => s.id === source.id)
    const newSelectedSources = alreadySelected ?
    this.state.selectedSources.filter((s) => s.id !== source.id) :
    [...this.state.selectedSources].concat([source])
    if (!this.state.maxSourcesReached) {
      if (this.maxSourcesReached(newSelectedSources)) {
        this.setState({ maxSourcesReached: true })
      }
      this.setState({ selectedSources: newSelectedSources })
    } else {
      if (!this.maxSourcesReached(newSelectedSources)) {
        this.setState({ maxSourcesReached: false })
        this.setState({ selectedSources: newSelectedSources })
      }
    }
  }

  private maxSourcesReached = (selectedSources: GlobalTypes.Source[]): boolean => {
    const { activeSourcesCount, maxAllowedSources } = this.state.sourcesInfo
    if ( activeSourcesCount && maxAllowedSources ) {
      return selectedSources.length + activeSourcesCount >= maxAllowedSources
    } else {
      // Stand-alone, non authenticated.  Nomaximums, this is just shopping
      return false
    }
  }

  private onClearSourcesSelections = (): void => {
    const { activeSourcesCount, maxAllowedSources } = this.state.sourcesInfo
    this.setState({
      maxSourcesReached: activeSourcesCount >= maxAllowedSources,
      selectedSources: [],
    })
  }

  private clearSearchTerm = (): void => {
    this.setState({searchTerm: ''})
    this.props.store.dispatch(((x) => ({type: CLEAR_SEARCH_TERM, payload: x}))({
      searchTerm: '',
    }))
  }

  private clearSelectedCategories = (): void => {
    this.setState({ selectedCategories: [], selectedSubCategories: [] })
    this.props.store.dispatch(((x) => ({type: CLEAR_SELECTED_CATEGORIES, payload: x}))({
      selectedCategory: {},
    }))
  }

  private reviewClick = () => {
    this.props.store.dispatch(((c) => ({type: REVIEW_CLICK, payload: c}))({
      selectedSources: this.state.selectedSources,
    }))
    this.props.navigate(Pages.reviewSelection)
  }

  private goBack = (): void => {
    this.clearSelectedCategories()
    if (this.state.senderPage === SEARCH_PAGE) {
      this.props.navigate(Pages.search)
    } else {
      this.props.navigate(Pages.selectCategory)
    }
  }
}

const SearchTermNotification = ({onClose, searchTerm}) => {
  return searchTerm
  ? (
  <div className="search-term-notification">
    <div className="text">
      {`You've searched for '${searchTerm}'`}
    </div>
    <div className="close" onClick={() => onClose()}>X</div>
  </div>)
  : <div className="search-term-notification-empty"/>
}
