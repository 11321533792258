import { GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'
import { LibraryIcon } from 'components/icons/library-icon'
import { Loading } from 'components/loading'
import { RasaPageComponent } from 'components/rasa-page-component'
import { RasaPageComponentProps } from 'components/rasa-page-component/types'
import * as GlobalTypes from 'components/types'
import { isAuthenticated, shouldShowBackButton } from 'generic/utility'
import React from 'react'
import { CLEAR_SELECTED_SOURCES, GAEvents, Pages, RESET_STORE } from 'rootConstants'
import { BackButtonComponent } from '../back-button'
import { SourcesListComponent } from '../content-library-page/sources-list'
import { addSources, sendGAEvent } from '../utils'
import './_styles.scss'
import * as Constants from './constants'
import * as Types from './types'

export class ReviewSelectionPageComponent
  extends RasaPageComponent<RasaPageComponentProps, Types.ReviewSelectionPageComponentState> {
  constructor(props) {
    super(props)
    const store = this.props.store.getState()

    this.state = {
      externalConfig: store.externalConfig || {},
      isDesktop: store.config.isDesktop,
      isLoading: true,
      initialSelectedSources: [],
      finalSelectedSources: [],
      skip: 0,
      sort: [],
      take: 25,
    }
  }

  public componentDidMount = () => {
    const contentLibraryPageState = this.props.store.getState().contentLibrary
    this.setState({
      isLoading: false,
      initialSelectedSources: contentLibraryPageState.selectedSources,
      finalSelectedSources: contentLibraryPageState.selectedSources,
    })
  }

  public render() {
    return (
      <div className="rct-review-selection-page">
        {shouldShowBackButton() && <div><BackButtonComponent onClick={this.goBack} /></div>}
        <div className="header-container">
          <div className="header-icon-container">
            <LibraryIcon />
          </div>
          <div className="header-txt-container">
            <div className="header-title">Review Selections</div>
            <div className="header-description">
              {Constants.REVIEW_PAGE_HEADER_DESCRIPTION}
            </div>
          </div>
        </div>
        {this.state.isLoading ? (
          <Loading size="64"></Loading>
        ) : (
          <div className="review-list-container">
            <SourcesListComponent
              data={this.state.initialSelectedSources}
              isAuthenticated={isAuthenticated(this.props.store)}
              isDesktop={this.state.isDesktop}
              onClearSourcesSelections={this.onClearSourcesSelections}
              onFindMoreSources={this.onFindMoreSources}
              navigate={this.props.navigate}
              onSelectSource={this.onSelectSource}
              onSelectionsClick={this.addSources}
              skip={this.state.skip}
              selectedSources={this.state.finalSelectedSources}
              setSort={this.setSort}
              setPage={this.setPage}
              sort={this.state.sort}
              take={this.state.take}
              total={this.state.initialSelectedSources.length}
              type={'review'}
            />
          </div>
        )}
      </div>
    )
  }

  private goBack = (): void => {
    this.props.navigate(Pages.contentLibrary)
  }

  private setSort = (e: GridSortChangeEvent): void => {
    this.setState({
      sort: e.sort,
    })
  }

  private setPage = (e: GridPageChangeEvent): void => {
    this.setState({
      skip: e.page.skip,
      take: e.page.take,
    })
  }

  private addSources = () => {
    addSources(this.state)
    this.setState({ finalSelectedSources: [] })
    this.props.store.dispatch(((c) => ({ type: RESET_STORE, payload: c }))({
      searchTerm: '',
      selectedCategory: {},
      selectedSources: [],
    }))
  }

  private onClearSourcesSelections = () => {
    this.setState({ finalSelectedSources: [] })
    this.props.store.dispatch(((c) => ({ type: CLEAR_SELECTED_SOURCES, payload: c }))({
        selectedSources: [],
      }))
  }

  private onSelectSource = (source: GlobalTypes.Source) => {
    const alreadySelected = this.state.finalSelectedSources.some((s) => s.id === source.id)
    const newSelectedSources = alreadySelected
      ? this.state.finalSelectedSources.filter((s) => s.id !== source.id)
      : [...this.state.finalSelectedSources].concat([source])

    this.setState({ finalSelectedSources: newSelectedSources })
    this.props.store.dispatch(((c) => ({ type: CLEAR_SELECTED_SOURCES, payload: c }))({
        selectedSources: newSelectedSources,
      }))
  }

  private onFindMoreSources = () => {
    sendGAEvent(this.props.store.getState(), {
      action: GAEvents.Page4FindMoreSources,
    })
    this.props.navigate(Pages.contentLibrary)
  }

}
