import { CategoryIcon } from 'components/icons/category-icon'
import { LibraryIcon } from 'components/icons/library-icon'
import { RasaPageComponent } from 'components/rasa-page-component'
import { RasaPageComponentProps } from 'components/rasa-page-component/types'
import { SearchBarComponent } from 'components/search-bar'
import { SearchTypeComponent } from 'components/search-type'
import React from 'react'
import {
  CONTENT_LIBRARY_PAGE,
  FILTER_BY_CATEGORY_TEXT,
  GAEvents,
  PAGE_HEADER_DESCRIPTION,
  Pages,
  SEARCH_PAGE,
  } from '../../rootConstants'
import { sendGAEvent } from '../utils'
import './_styles.scss'

interface SearchPageComponentState {
  searchTerm: string
}

export class SearchPageComponent extends RasaPageComponent<RasaPageComponentProps, SearchPageComponentState> {
  constructor(props) {
    super(props)

    this.state = {
      searchTerm: '',
    }
  }

  public render() {
    return (
      <div className="rct-search-page">
        <div className="header-img">
          <LibraryIcon />
        </div>
        <div className="header-txt">Content library</div>
        <div className="header-desc-txt">{PAGE_HEADER_DESCRIPTION}</div>
        <SearchBarComponent onSubmit={this.searchSubmit}/>
        <div className="or-txt">or</div>
        <SearchTypeComponent
          icon={<CategoryIcon />}
          title={FILTER_BY_CATEGORY_TEXT}
          onSubmit={this.searchByCategory}
        />
      </div>
    )
  }

  private searchSubmit = (term: string) => {
    this.props.store.dispatch(((x) => ({type: CONTENT_LIBRARY_PAGE, payload: x}))({
      searchTerm: term,
      senderPage: SEARCH_PAGE,
    }))
    sendGAEvent(this.props.store.getState(), {action: GAEvents.Page1Search, label: term})
    this.props.navigate(Pages.contentLibrary)
  }

  private searchByCategory = (e) => {
    sendGAEvent(this.props.store.getState(), {action: GAEvents.Page1Categories})
    this.props.navigate(Pages.selectCategory)
  }
}
