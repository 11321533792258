import {Fade} from 'components/fade'
import { BouncingBallsLarge } from 'components/loading/balls'
import { BouncingBallsSmall } from 'components/loading/balls'

import * as React from 'react'
import './styles.css'

export type Sizes = '64' | '32' | '16'
export const SIZES: {[key: string]: Sizes} = {
  LARGE: '64',
  MEDIUM: '32',
  SMALL: '16',
}
interface Props {size: Sizes}

//tslint:disable-next-line
export const Loading: React.SFC<Props> = ({size}) => <Fade>
    <div className="loading">
      {size === SIZES.LARGE ?
      <BouncingBallsLarge/> :
      <BouncingBallsSmall/>
      }
    </div>
</Fade>
