import { BackIcon } from 'components/icons/back'
import React, {Component} from 'react'
import {Button, Label} from 'reactstrap'
import './_styles.scss'

interface BackButtonComponentProps {
  onClick: any,
}

export class BackButtonComponent extends Component<BackButtonComponentProps> {
  constructor(props) {
    super(props)
  }

  public render() {
    return (
      <Button className="rct-button rct-back-button" onClick={this.props.onClick}>
        <BackIcon />
        <Label>Back</Label>
      </Button>
    )
  }
}
