import { ADD_SOURCES, RCTResponse, SEND_GA_EVENT } from '../rootConstants'

export const sendGAEvent = (state, payload) => {
  if (state.externalConfig.callback) {
    const response: RCTResponse = {
      success: true,
      payload: {
        data: payload,
        type: SEND_GA_EVENT,
      },
    }
    state.externalConfig.callback(response)
  }
}

export const addSources = (state) => {
  if (state.externalConfig.callback) {
    const response: RCTResponse = {
      success: true,
      payload: {
        data: state.finalSelectedSources,
        type: ADD_SOURCES,
      },
    }
    state.externalConfig.callback(response)
  }
}
