export interface SourcesInfo {
  activeSourcesCount: number,
  maxAllowedSources: number
}

export interface ExternalConfig {
  callback?: any,
  currentPage?: string,
  identifier?: string,
  parentId?: string,
  rasaToken: string,
  selectedCategory?: string,
  sourcesInfo?: SourcesInfo,
}

export const EMPTY_CONFIG: ExternalConfig = {
  callback: null,
  currentPage: '',
  identifier: '',
  parentId: '',
  rasaToken: '',
  selectedCategory: '',
  sourcesInfo: {
    activeSourcesCount: null,
    maxAllowedSources: null,
  },
}
