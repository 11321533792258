import { CategoryIcon } from 'components/icons/category-icon'
import { NextIcon } from 'components/icons/next'
import { Loading } from 'components/loading'
import { RasaPageComponent } from 'components/rasa-page-component'
import { RasaPageComponentProps } from 'components/rasa-page-component/types'
import { Dataset } from 'generic/dataset'
import React, { Component } from 'react'
import { shouldShowBackButton } from '../../generic/utility'
import { CONTENT_LIBRARY_PAGE,
  FILTER_BY_CATEGORY_TEXT,
  GAEvents,
  PAGE_HEADER_DESCRIPTION,
  Pages,
  SELECT_CATEGORY_PAGE } from '../../rootConstants'
import '../_styles.scss'
import {BackButtonComponent} from '../back-button'
import { sendGAEvent } from '../utils'
import './_styles.scss'
import { Category } from './types'

interface SelectCategoryPageState {
  categories: Category[]
}

export class SelectCategoryPageComponent extends RasaPageComponent<RasaPageComponentProps, SelectCategoryPageState> {
  constructor(props: any) {
    super(props)

    this.state = {
      categories: [],
    }
  }

  public componentDidMount() {
    const datasetParams = [{param: 'limit', value: 10}]
    return new Dataset().loadPublicDataset('categories', datasetParams)
    .then((result) => {
      this.setState({categories: result[0]})
    })
  }

  public render() {
    return (
      <div className="rct-select-category-page">
        {shouldShowBackButton() && <div><BackButtonComponent onClick={this.goBack}/></div>}
        <div className="header-img"><CategoryIcon /></div>
        <div className="header-txt">{FILTER_BY_CATEGORY_TEXT}</div>
        <div className="header-desc-txt">{PAGE_HEADER_DESCRIPTION}</div>
        {
          this.state.categories.length > 0
          ? <div>
              <div>
                <SelectCategoryComponent
                  categories={this.state.categories}
                  store={this.props.store}
                  navigate={this.props.navigate}
                />
              </div>
              <div className="skip-container"><a onClick={this.onSkip}>Skip</a></div>
            </div>
          : <Loading size="64" />
        }
      </div>
    )
  }

  private onSkip = () => {
    this.props.store.dispatch(((x) => ({type: CONTENT_LIBRARY_PAGE, payload: x}))({
      searchTerm: '',
      selectedCategory: {},
      senderPage: SELECT_CATEGORY_PAGE,
    }))

    this.props.navigate(Pages.contentLibrary)
  }

  private goBack = () => {
    this.props.navigate(Pages.search)
  }
}

interface SelectCategoryComponentProps {
  categories: Category[],
  navigate: any,
  store: any,
}

class SelectCategoryComponent extends Component<SelectCategoryComponentProps> {
  constructor(props) {
    super(props)
  }

  public render() {
    return (
      <div className="rct-select-category">
        {this.props.categories.map((category: Category, index: number) =>
          <div key={index} className="cursor-pointer wrapper" onClick={() => this.categoryClick(category)}>
            <div className="name"><span>{category.name}</span></div>
            <div className="icon"><NextIcon /></div>
          </div>,
        )}
      </div>
    )
  }

  private categoryClick = (category: Category) => {
    this.props.store.dispatch(((c) => ({type: CONTENT_LIBRARY_PAGE, payload: c}))({
      selectedCategory: category,
      senderPage: SELECT_CATEGORY_PAGE,
    }))
    sendGAEvent(this.props.store.getState(), {action: GAEvents.Page2CategorySelect, label: category.name})
    this.props.navigate(Pages.contentLibrary)
  }
}
