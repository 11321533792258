import * as Redux from 'redux'
import {
  CLEAR_SEARCH_TERM, CLEAR_SELECTED_CATEGORIES, CLEAR_SELECTED_SOURCES,
  CONTENT_LIBRARY_PAGE, RESET_STORE, REVIEW_CLICK,
  } from '../../rootConstants'
import * as GlobalTypes from '../types'

export interface State {
  searchTerm: string,
  selectedCategory: any,
  senderPage: string,
  selectedSources: GlobalTypes.Source[],
}
const initialState: State = {
  searchTerm: '',
  selectedCategory: {},
  senderPage: '',
  selectedSources: [],
}

interface Action extends Redux.Action {payload: any}

export type Reducer = Redux.Reducer<State, Redux.Action & Action>
export const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTENT_LIBRARY_PAGE:
    case CLEAR_SEARCH_TERM:
    case CLEAR_SELECTED_SOURCES:
    case CLEAR_SELECTED_CATEGORIES:
    case RESET_STORE:
    case REVIEW_CLICK:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
