import { SearchIcon } from 'components/icons/search-icon'
import React, {Component} from 'react'
import { Button } from 'reactstrap'
import './_styles.scss'

interface SearchBarProps {
  inputChange?: any,
  label?: string,
  onSubmit?: any,
}

interface SearchBarState {
  searchTerm: string,
}

export class SearchBarComponent extends Component<SearchBarProps, SearchBarState> {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
    }
  }

  public render() {
    return (
      <div className="rct-search-bar">
        <div className="input-container">
          <SearchIcon/>
          <input type="text"
            placeholder="Search for a source..."
            value={this.state.searchTerm}
            onChange={(e) => this.setState({searchTerm: e.target.value})}
            onKeyPress={(e) => this.enterPressed(e)}
          />
        </div>
        <Button className="rct-button search-submit" onClick={this.handleClick}>
          <div className="search-label">
            {this.props.label || 'Search'}
          </div>
        </Button>
      </div>
    )
  }

  private handleClick = () => {
    this.props.onSubmit(this.state.searchTerm)
    this.setState({searchTerm: ''})
  }

    private enterPressed = (e) => {
    const code = e.keyCode || e.which
    if (code === 13) {
      this.handleClick()
    }
  }

}
