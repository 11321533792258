import {ajax} from 'rxjs/ajax'

declare const RASA_SELF_SERVICE_API: string

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

//tslint:disable-next-line
class _AjaxWrapper {
  private token: string = null

  public getServerUrl(): string {
    return RASA_SELF_SERVICE_API
  }

  public setToken(token: string): void {
    this.token = token
  }

  // override this method to change the default ajax implementation (which uses RxJs in the base class)
  public ajax(
    url: string,
    method: HttpMethod,
    body: object,
    contentType: string = 'application/json',
  ) {
    const headers = {
      'Authorization': `Bearer ${this.token}`,
      'rxjs-custom-header': 'Rxjs',
    }
    if ( contentType ) {
      headers['Content-Type'] = contentType
    }

    return ajax({
      body,
      headers,
      method,
      url,
    }).toPromise().then(({response}) => response)
  }
}

//tslint:disable-next-line
export const AjaxWrapper = new _AjaxWrapper()
