export const EXTERNAL_CONFIG: string = 'EXTERNAL_CONFIG'
export const SEARCH_PAGE: string = 'SEARCH_PAGE'
export const SELECT_CATEGORY_PAGE: string = 'SELECT_CATEGORY_PAGE'
export const CONTENT_LIBRARY_PAGE: string = 'CONTENT_LIBRARY_PAGE'
export const CLEAR_SELECTED_CATEGORIES = 'CLEAR_SELECTED_CATEGORIES'
export const CLEAR_SEARCH_TERM = 'CLEAR_SEARCH_TERM'
export const REVIEW_CLICK = 'REVIEW_CLICK'
export const CLEAR_SELECTED_SOURCES = 'CLEAR_SELECTED_SOURCES'
export const RESET_STORE = 'RESET_STORE'

export const Pages = {
  search: 'search',
  selectCategory: 'selectCategory',
  contentLibrary: 'contentLibrary',
  reviewSelection: 'reviewSelection',
}

export const PAGE_HEADER_DESCRIPTION = 'Use our content library to help you find relevant sources' +
  ' for your newsletter.'
export const FILTER_BY_CATEGORY_TEXT = 'Filter by specific category'

export const ADD_SOURCES = 'addSources'
export const SEND_GA_EVENT = 'sendGAEvent'

export enum GAEvents {
  Page1Search = 'Page1Search',
  Page1Categories = 'Page1Categories',
  Page2CategorySelect = 'Page2CategorySelect',
  Page3Search = 'Page3Search',
  Page3CategorySelect = 'Page3CategorySelect',
  Page3SubCategorySelect = 'Page3SubCategorySelect',
  Page4FindMoreSources = 'Page4FindMoreSources',
}

export interface CallbackPayload {
  data: any,
  type: string,
}

export interface RCTResponse {
  payload: CallbackPayload,
  success: boolean,
}
