import * as React from 'react'

export const AddIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" className="add-icon" viewBox="0 0 27 27">
    <g id="Group_6968" data-name="Group 6968" transform="translate(-1257 -497)">
        <g id="Ellipse_11" fill="#fff" stroke="#e6e6e6" data-name="Ellipse 11" transform="translate(1257 497)">
            <circle cx="13.5" cy="13.5" r="13.5" stroke="none"/>
            <circle cx="13.5" cy="13.5" r="13" fill="none"/>
        </g>
        <g id="plus" transform="translate(1265.02 505.13)">
            <g id="Group_6932" data-name="Group 6932">
                <g id="Group_6931" data-name="Group 6931">
                    <path id="Path_22288" fill="#001927" d="M146.855 139.708h-3.575v-3.576a.894.894 0 1 0-1.788 0v3.576h-3.576a.894.894 0 0 0 0 1.788h3.576v3.576a.894.894 0 1 0 1.788 0V141.5h3.576a.894.894 0 0 0 0-1.788z" data-name="Path 22288" transform="translate(-137.022 -135.238)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
