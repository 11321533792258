import { Loading } from 'components/loading'
import React, { Component } from 'react'
import { Input, Label } from 'reactstrap'
import * as CommonConstants from '../constants'
import * as GlobalTypes from '../types'
import './_styles.scss'
import * as Constants from './constants'
import * as Types from './types'

export class FiltersComponent extends Component<Types.FiltersComponentProps, Types.FiltersComponentState> {
  constructor(props) {
    super(props)
    this.state = {
      categoriesExpanded: false,
      counts: {[Constants.CATEGORIES]: {}, [Constants.SUB_CATEGORIES]: {}},
      filteredCounts: {[Constants.CATEGORIES]: {}, [Constants.SUB_CATEGORIES]: {}},
      subCategoriesExpanded: false,
      isLoading: true,
      selectedCategories: [],
      selectedSubCategories: [],
    }
  }

  public componentDidUpdate(prevProps: Readonly<Types.FiltersComponentProps>): void {
    if (prevProps.sources !== this.props.sources) {
      this.getCounts()
    }
  }

  public componentDidMount = () => {
    this.getCounts().then(() => this.initialize())
  }

  public render() {
    return (
    <div className="filters-container">
      { this.state.isLoading
      ? <Loading size="64" />
      : this.props.categories &&
      <div className="categories-and-subcategories-container">
        <div className="selected-categories-container">
          {this.state.selectedCategories.length
          ? <span className="clear-all" onClick={this.clearAll}>
            Clear all</span>
          : <span className="empty-clear-all"/>}
          <span className="filter-by">Filter by</span>
          {!!this.state.selectedCategories.length
          ?
          <div className="list">
          {this.state.selectedCategories.map((c: any, i: number) => {
            const name = this.props.categories.filter((category) => category.id === c.id)[0].name
            return <Bubble key={i} name={name} id={c.id} onClick={this.removeSelectedCategory}/>
          })}
          </div>
          : <div className="empty-list"/>
          }
        </div>
        <div className="title">Category</div>
        <div className="categories-container">
          {this.getCategories()}
        </div>
        {this.props.categories.length > Constants.CATEGORIES_COUNT_BEFORE_EXPAND &&
        <div className="expander" onClick={() => this.toggle(Constants.CATEGORIES)}>
          {this.state.categoriesExpanded ? 'Show less -' : 'Show more +'}
        </div>
        }
        {!!this.state.selectedCategories.length &&
        <div>
          <div className="divider"></div>
          <div className="title">Sub Category</div>
          <div className="subcategories-container">
          {(this.state.subCategoriesExpanded ? this.subCategoriesToDisplay()
            : this.subCategoriesToDisplay().slice(0, Constants.SUB_CATEGORIES_COUNT_BEFORE_EXPAND)
          ).map((item: any, i) =>
            <Filter
              key={i}
              name={item.name}
              counts={this.state.counts}
              filteredCounts={this.state.filteredCounts}
              isSearched={this.props.searchTerm !== ''}
              id={item.id}
              select={this.onSelectSubCategory}
              subCategoriesMapping={this.props.subCategoriesMapping}
              type={Constants.SUB_CATEGORIES}
              selectedCategories={this.state.selectedCategories}
              selectedSubCategories={this.state.selectedSubCategories}
            />)
          }
          </div>
          { this.subCategoriesToDisplay().length > Constants.SUB_CATEGORIES_COUNT_BEFORE_EXPAND &&
            <div className="expander" onClick={() => this.toggle(Constants.SUB_CATEGORIES)}>
            {this.state.subCategoriesExpanded ? 'Show less -' : 'Show more +'}
          </div>
          }
        </div>
        }
      </div>
      }
    </div>
    )
  }

  private getCategories = () => {
    // const filteredCategories = this.props.categories.filter((c: any) => this.shouldShowCategory(c))
    const categoriesToShow = this.state.categoriesExpanded ? this.props.categories
    : this.props.categories.filter((x) => x.rank === 1)
    return categoriesToShow.map((item: any, i) =>
      <Filter
        key={i}
        name={item.name}
        counts={this.state.counts}
        filteredCounts={this.state.filteredCounts}
        isSearched={this.props.searchTerm !== ''}
        id={item.id}
        select={this.onSelectCategory}
        subCategoriesMapping={this.props.subCategoriesMapping}
        type={Constants.CATEGORIES}
        selectedCategories={this.state.selectedCategories}
        selectedSubCategories={this.state.selectedSubCategories}
      />)
  }
  private initialize = () => {
    const initialSelectedCategory = this.props.categories
      .filter((category) => category.name === this.props.selectedCategoryName)
      .map((c) => ({id: c.id, name: c.name, parentId: c.parent_id}))
    this.setState({ isLoading: false, selectedCategories: initialSelectedCategory })
  }

  private clearAll = () => {
    this.setState({selectedCategories: [], selectedSubCategories: []})
    this.props.onClearAll()
  }

  private toggle = (type) => {
    if (type === Constants.CATEGORIES) {
      this.setState({ categoriesExpanded : !this.state.categoriesExpanded })
    } else if (type === Constants.SUB_CATEGORIES) {
      this.setState({ subCategoriesExpanded : !this.state.subCategoriesExpanded })
    }
  }

  private subCategoriesToDisplay = (): GlobalTypes.Category[] => {
    if (this.state.selectedCategories.length) {
      return this.props.subCategories.filter((sc) =>
        this.state.selectedCategories.map((selectedCategory) => selectedCategory.id).includes(sc.parent_id))
    } else {
      return []
    }
  }

  private getCounts = () => {
    return this.handleCounts(this.props.fullSources, 'counts').then(() => {
      return this.handleCounts(this.props.sources, 'filteredCounts')
    })
  }

  private handleCounts = (sources: GlobalTypes.Source[], field: string) => {
    return new Promise((resolve, reject) => {
      const result = sources.reduce((acc, cur) => {
        if (!cur.category) {
          return acc
        }

        const curCategoryIdsArray = cur.category_ids.split(CommonConstants.CATEGORY_SPLIT_CHAR)
        curCategoryIdsArray.forEach((c: string, catIndex: number) => {
          const currentCategoryId = parseInt(c, 10)
          if (acc[Constants.CATEGORIES][currentCategoryId]) {
            acc[Constants.CATEGORIES][currentCategoryId] = {
              ...acc[Constants.CATEGORIES][currentCategoryId],
              count: acc[Constants.CATEGORIES][currentCategoryId].count + 1,
            }
          } else {
            const curCategoryNamesArray = cur.category.split(CommonConstants.CATEGORY_SPLIT_CHAR)
            acc[Constants.CATEGORIES][currentCategoryId] = {
              name: curCategoryNamesArray[catIndex], // should be same index
              count: 1,
            }
          }

          if (cur.sub_category_ids) {
            const curSubCategoryArray = cur.sub_category_ids.split(CommonConstants.CATEGORY_SPLIT_CHAR)
            curSubCategoryArray.forEach((sc: string, subCatIndex: number) => {
              sc = sc.trim()
              if (sc) {
                const currentSubCategoryId = parseInt(sc, 10)
                const curSubCategoryNamesArray = cur.sub_category.split(CommonConstants.CATEGORY_SPLIT_CHAR)
                const subCategoryName = curSubCategoryNamesArray[subCatIndex] // should be same index

                // does this sub category belong to current category?
                if (this.props.subCategoriesMapping[currentSubCategoryId] &&
                  this.props.subCategoriesMapping[currentSubCategoryId].category_id === currentCategoryId) {
                  if (acc[Constants.SUB_CATEGORIES][currentCategoryId]) {
                    if (acc[Constants.SUB_CATEGORIES][currentCategoryId][currentSubCategoryId]) {
                      // already have this category and sub category
                      acc[Constants.SUB_CATEGORIES][currentCategoryId][currentSubCategoryId] = {
                        ...acc[Constants.SUB_CATEGORIES][currentCategoryId][currentSubCategoryId],
                        count: acc[Constants.SUB_CATEGORIES][currentCategoryId][currentSubCategoryId].count + 1,
                      }
                    } else {
                      // haven't built for this SUB CATEGORY yet
                      acc[Constants.SUB_CATEGORIES][currentCategoryId][currentSubCategoryId] = {
                        name: subCategoryName,
                        count: 1,
                      }
                    }
                  } else {
                    // haven't built for this CATEGORY yet
                    acc[Constants.SUB_CATEGORIES][currentCategoryId] = {
                      [currentSubCategoryId]: {
                        name: subCategoryName,
                        count: 1,
                      },
                    }
                  }
                }
              }
            })
          }
        })
        return acc
      }, {[Constants.CATEGORIES]: {}, [Constants.SUB_CATEGORIES]: {}})
      this.setState({
        ...this.state,
        [field]: result,
      }, () => resolve(null))
    })
  }

  private onSelectCategory = (e: React.FormEvent<HTMLInputElement>, selectedProps: Types.SelectedProps) => {
    const checked = e.currentTarget.checked
    let newSelectedSubCategories = this.state.selectedSubCategories
    const newSelectedCategories = checked
      ? [...this.state.selectedCategories].concat([
          {
            id: selectedProps.id,
            name: selectedProps.name,
            parentId: selectedProps.parentId,
          },
        ])
      : this.state.selectedCategories.filter(
          (selectedCategory) => selectedCategory.id !== selectedProps.id,
        )

    this.setState({selectedCategories: newSelectedCategories})
    if (!checked) {
      // if any of sub category of the current category is checked, make sure to remove it
      newSelectedSubCategories = this.state.selectedSubCategories.filter((selectedSubCategory) =>
        selectedSubCategory.parentId !== selectedProps.id)
      this.setState({selectedSubCategories: newSelectedSubCategories})
    }

    const categoryName = this.props.categories.filter((c) => c.id === selectedProps.id)[0].name
    this.props.onCategoryChanged(categoryName, newSelectedSubCategories)
  }

  private onSelectSubCategory = (e: React.FormEvent<HTMLInputElement>, selectedProps: Types.SelectedProps) => {
    const newSelectedSubCategories = e.currentTarget.checked
      ? [...this.state.selectedSubCategories].concat([
          {
            id: selectedProps.id,
            name: selectedProps.name,
            parentId: selectedProps.parentId,
          },
        ])
      : this.state.selectedSubCategories.filter(
          (selectedSubCategory) => selectedSubCategory.id !== selectedProps.id,
        )

    this.setState({selectedSubCategories: newSelectedSubCategories})

    const subCategoryName = this.props.subCategories.filter((sc) => sc.id === selectedProps.id)[0].name
    this.props.onSubCategoryChanged(subCategoryName)
  }

  private removeSelectedCategory = (name: string, id: number) => {
    const newSelectedCategories = this.state.selectedCategories.filter((selectedCategory) => selectedCategory.id !== id)
    this.setState({selectedCategories: newSelectedCategories})
    this.props.onCategoryChanged(name, this.state.selectedSubCategories)
  }
}

const Filter = (filterProps: Types.FilterProps) => {
  const {counts, isSearched, filteredCounts, id, name, selectedCategories,
    selectedSubCategories, subCategoriesMapping, type} = filterProps
  let parentId = null
  let checked = false
  let currentCount = 0
  let fullCount = 0
  if (type === Constants.CATEGORIES) {
    fullCount = counts[type][id] ? counts[type][id].count : 0
    if (isSearched) {
      currentCount = filteredCounts[type][id] ? filteredCounts[type][id].count : 0
    } else {
      currentCount = fullCount
    }
    checked = selectedCategories.map((selectedCategory) => selectedCategory.id).includes(id)
  } else {
    // find the category using mapping
    parentId = subCategoriesMapping[id] ? subCategoriesMapping[id].category_id : null
    if (parentId) {
      fullCount = counts[type][parentId] && counts[type][parentId][id] ? counts[type][parentId][id].count : 0
      if (isSearched) {
        currentCount = filteredCounts[type][parentId] && filteredCounts[type][parentId][id]
          ? filteredCounts[type][parentId][id].count : 0
      } else {
        currentCount = fullCount
      }
      checked = selectedSubCategories.map((selectedSubCategory) => selectedSubCategory.id).includes(id)
    }
  }

  return (fullCount > 0) ? <div className="filter-container">
        <Input
          type="checkbox"
          checked={checked}
          onChange={(e) => filterProps.select(e, {...filterProps, parentId})}>
        </Input>
        <Label>{`${name} (${currentCount})`}</Label>
      </div> : null
}

const Bubble = (bubbleProps: Types.BubbleProps) => {
  return (
    <div className="bubble">
      <span className="text">{bubbleProps.name}</span>
      <span
        className="close"
        onClick={() => bubbleProps.onClick(bubbleProps.name, bubbleProps.id)}
      >X</span>
    </div>
  )
}
