import { Grid, GridColumn, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'
import classnames from 'classnames'
import { AddIcon } from 'components/icons/add-icon'
import { CheckmarkIcon } from 'components/icons/checkmark'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import * as CommonConstants from '../constants'
import * as GlobalTypes from '../types'
import './_styles.scss'
import * as Types from './types'

export class SourcesListComponent extends Component<Types.SourcesListComponentProps> {
  constructor(props: Types.SourcesListComponentProps) {
    super(props)
  }

  public render() {
    const { data, selectedSources, setPage, setSort, skip, sort, take, total, totalSources, type } = this.props
    return (
      <div className="sources-list">
        <div className="display-count-and-review-container">
          {type === CommonConstants.SELECTION &&
          <div className="display-count">
            {total === totalSources
            ? `Showing all ${total} sources`
            : `Showing ${total} of ${totalSources} sources`
            }
            </div>}
          {type === CommonConstants.REVIEW &&
          <div className="display-count">{`Reviewing your (${data.length}) ${pluralize('selection', data.length, false)}`}</div>}
          {type === CommonConstants.SELECTION
          ? <div>
              {selectedSources.length
              ? <div className="review-selections" onClick={() => this.props.onSelectionsClick()}>
                {`Review my ${pluralize('selection', selectedSources.length, false)} (${selectedSources.length})`}
                </div>
              : <div className="empty-review-selections"/>
              }
            </div>
          : <div className="add-selections" onClick={() => this.props.onSelectionsClick()}>
              {`Add (${selectedSources.length}) ${pluralize('source', selectedSources.length, false)}`}
            </div>
          }
        </div>
        <Grid
            data={data}
            skip={skip}
            take={take}
            total={total}
            onPageChange={(e: GridPageChangeEvent) => setPage(e)}
            sortable={{
              allowUnsort: true,
            }}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => setSort(e)}
            pageable={true}
            pageSize={25}
            rowRender={this.customRow}
            >
          <GridColumn field="name" title={'Source Name & Url'} width={200}/>
          <GridColumn field="category" title="Category" width={150}/>
          {this.props.isDesktop &&
          <GridColumn field="sub_category" title="Sub Category" width={100}/>
          }
          {this.props.isAuthenticated &&
          <GridColumn field=""
                      title={`Clear ${pluralize('selection', selectedSources.length, false)}`}
                      width={125}
                      headerCell={this.ClearSelectionHeaderCell}/>}
      </Grid>
      {type === CommonConstants.REVIEW &&
      <div className="add-more" onClick={() => this.props.onFindMoreSources()}>
        {`Find more sources`}
      </div>}
      </div>
    )
  }

  private customRow = (row, props) => {
    const {category, identifier, name, options, type, sub_category} = props.dataItem
    const url = type !== GlobalTypes.SourceTypes.facebook
    ? identifier
    : options ? JSON.parse(options).url : null
    return (
      <tr className="custom-row">
        <td className="source-name-and-identifier">
          <div className="source-name">{name}</div>
          {!!url &&
          <div className="source-identifier">{url}</div>}
        </td>
        <td className="source-category">
          {category
          ? category.split(CommonConstants.CATEGORY_SPLIT_CHAR).map((c, i) =>
          <span className="bubble-text" key={i}>
            {c}
          </span>)
          : null}
        </td>
        {this.props.isDesktop &&
        <td className={classnames('source-sub-category', {corner: !this.props.isAuthenticated})}>
          {sub_category
          ? sub_category.split(CommonConstants.CATEGORY_SPLIT_CHAR).map((sc, i) =>
          <span className={'bubble-text'} key={i}>
            {sc}
          </span>)
          : null}
        </td>}
        {this.props.isAuthenticated &&
        <td className="source-select corner" onClick={(e) => this.props.onSelectSource(props.dataItem)}>
          {this.isSelected(props.dataItem) ?
          <CheckmarkIcon/> :
          <AddIcon/>}
        </td>}
      </tr>
    )
  }

  private ClearSelectionHeaderCell = (props) => {
    return this.props.isAuthenticated
    ? <span className="k-link clear-selection-header" onClick={() => this.props.onClearSourcesSelections()}>
      {props.title}
      </span>
    : <span className="k-link clear-selection-header"/>
    }

  private isSelected = (source: GlobalTypes.Source): boolean => {
    return this.props.selectedSources.some((s) => s.id === source.id)
  }
}
