import '@progress/kendo-theme-default/dist/all.css'
import 'symbol-observable'
//import this polyfill for older browser support
//import 'core-js/stable'
/////////////////////////////////////////////////////////////////////
// ATTEMPTED TO ADD IN PETER'S KENDO THEME HERE BUT IT CAUSES WEIRD ERRORS
/////////////////////////////////////////////////////////////////////

import * as Sentry from '@sentry/browser'
import { Subject } from 'rxjs'
import { withLatestFrom } from 'rxjs/operators'

import * as History from 'history'
import * as React from 'react'
import { render } from 'react-dom'
import * as Redux from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import createSentryMiddleware from 'redux-sentry-middleware'
import { from, fromEventPattern } from 'rxjs'

import './_main.scss'
import { RootComponent } from './components/home'
import { EMPTY_CONFIG, ExternalConfig } from './external-config/types'
import { AjaxWrapper } from './generic/ajaxWrapper'
import * as RootConstants from './rootConstants'
import { rootEpic } from './rootEpic'
import { rootReducer } from './rootReducer'
import { configureStore } from './store/index'

declare const SENTRY_URL: string
declare const RASA_SELF_SERVICE_API: string
declare const BITBUCKET_COMMIT: string
declare const RASA_IMGIX_URL: string
declare const DASHBOARD_ENV: string

const mount = document.createElement('div') as HTMLElement
document.body.appendChild(mount)
const history = History.createBrowserHistory()
const history$ = fromEventPattern(history.listen)

const dependencies = {
  history$,
  history,
  localStorage,
  window,
}
const epicMiddleware = createEpicMiddleware({dependencies})

const action$ = new Subject()
Sentry.init({
  dsn: SENTRY_URL,
  environment: DASHBOARD_ENV,
  release: `dashboard-ui@${BITBUCKET_COMMIT}`,
})

const actionMiddleware = ({getState}) => (next) => (action) => {
  action$.next(action)
  return next(action)
}

const isTablet = window.matchMedia('(min-width:600px) and (max-width: 1200px)').matches
const isMobile = window.matchMedia('(max-width: 600px)').matches
const isDesktop = window.matchMedia('(min-width: 1400px)').matches
const store = configureStore(rootReducer, {
    config: {
      api: RASA_SELF_SERVICE_API,
      imgixUrl: RASA_IMGIX_URL,
      isDesktop,
      isMobile,
      isTablet,
    },
  },
  composeWithDevTools(
      Redux.applyMiddleware(
        epicMiddleware,
        createSentryMiddleware(Sentry),
        actionMiddleware,
      ),
    ),
  )

epicMiddleware.run(rootEpic)
const store$ = from(store)
action$.pipe(withLatestFrom(store$))

const createContainerElement = () => {
  document.body.innerHTML += '<div id="rasa-content-tool" />'
  return document.getElementById('rasa-content-tool')
}

export const init = (externalConfig: ExternalConfig) => {
  AjaxWrapper.setToken(externalConfig.rasaToken)
  initializeState({
    ...EMPTY_CONFIG,
    ...externalConfig,
  })
}

const initializeState = (externalConfig: ExternalConfig) => {
  store.dispatch(((c) => ({type: RootConstants.EXTERNAL_CONFIG, payload: c}))(externalConfig))
  store.dispatch(((x) => ({type: RootConstants.CONTENT_LIBRARY_PAGE, payload: x}))({}))
  store.dispatch(((x) => ({type: RootConstants.CLEAR_SEARCH_TERM, payload: x}))({}))
  store.dispatch(((x) => ({type: RootConstants.CLEAR_SELECTED_SOURCES, payload: x}))({}))
  store.dispatch(((x) => ({type: RootConstants.CLEAR_SELECTED_CATEGORIES, payload: x}))({}))
  store.dispatch(((x) => ({type: RootConstants.REVIEW_CLICK, payload: x}))({}))
}

export const show = () => {
  const externalConfig = store.getState().externalConfig || {}
  let container = document.getElementById(externalConfig.parentId)
  if (!container) {
    container = createContainerElement()
  }
  return render(<RootComponent store={store} />, container)
}
