import React, {Component} from 'react'
import {Pages} from '../../rootConstants'
import {ContentLibraryPageComponent} from '../content-library-page'
import { ReviewSelectionPageComponent } from '../review-selection-page'
import {SearchPageComponent} from '../search-page'
import {SelectCategoryPageComponent} from '../select-category-page'
import './_styles.scss'
import {RootProps} from './types'

interface RootState {
  currentPage: any,
}

export class RootComponent extends Component<RootProps, RootState> {
  constructor(props) {
    super(props)
    const externalConfig = this.props.store.getState().externalConfig || {}
    this.state = {
      currentPage: externalConfig.currentPage || Pages.search,
    }
  }

  public render() {
    return (
      <div className="rct-root-container">
        {this.state.currentPage === Pages.search && <SearchPageComponent {...this.pageProps()} />}
        {this.state.currentPage === Pages.selectCategory && <SelectCategoryPageComponent {...this.pageProps()}/>}
        {this.state.currentPage === Pages.contentLibrary && <ContentLibraryPageComponent {...this.pageProps()}/>}
        {this.state.currentPage === Pages.reviewSelection && <ReviewSelectionPageComponent {...this.pageProps()}/>}
      </div>
    )
  }

  private pageProps() {
    return {
      navigate: this.navigate,
      store: this.props.store,
    }
  }

  private navigate = (page) => {
    this.setState({currentPage: Pages[page]})
  }
}
