// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading svg {
  animation-name: round;
  animation-duration: 1.7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes round {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/loading/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,wBAAwB;EACxB,mCAAmC;EACnC,iCAAiC;AACnC;AACA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,wBAAwB;EAC1B;AACF;AACA;EACE;IACE,gCAAgC;EAClC;EACA;IACE,oCAAoC;EACtC;EACA;IACE,kCAAkC;EACpC;AACF","sourcesContent":[".loading svg {\n  animation-name: round;\n  animation-duration: 1.7s;\n  animation-iteration-count: infinite;\n  animation-timing-function: linear;\n}\n@keyframes bounce {\n  0% {\n    transform: translateY(0);\n  }\n  50% {\n    transform: translateY(1rem);\n  }\n  100% {\n    transform: translateY(0);\n  }\n}\n@keyframes round {\n  0% {\n    transform: rotate(0deg) scale(1);\n  }\n  50% {\n    transform: rotate(180deg) scale(1.2);\n  }\n  100% {\n    transform: rotate(360deg) scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
