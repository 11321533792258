// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-icon-cls-1 {
  fill:none
}
.library-icon-cls-3 {
  fill:#78c8c4
}
.library-icon-cls-4 {
  fill:#e0e0df
}
.library-icon-cls-5 {
  fill:#ffe100
}`, "",{"version":3,"sources":["webpack://./src/components/icons/library-icon.css"],"names":[],"mappings":"AAAA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF","sourcesContent":[".library-icon-cls-1 {\n  fill:none\n}\n.library-icon-cls-3 {\n  fill:#78c8c4\n}\n.library-icon-cls-4 {\n  fill:#e0e0df\n}\n.library-icon-cls-5 {\n  fill:#ffe100\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
