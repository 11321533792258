import * as React from 'react'
import './back.css'

export const BackIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width="7.191" height="13.062" viewBox="0 0 7.191 13.062">
  <defs>

  </defs>
  <g id="back_1_" data-name="back (1)" transform="translate(0 0)">
    <path id="Chevron_Right" className="backicon" d="M54.3,7l5.874,5.872a.66.66,0,1,0,.934-.932L55.7,6.532l5.407-5.406a.66.66,0,1,0-.934-.932L54.3,6.065A.665.665,0,0,0,54.3,7Z" transform="translate(-54.113 -0.001)"/>
  </g>
</svg>
