import { isString } from 'lodash'

declare const DASHBOARD_ENV: string

const falseValues = ['0', 'n', 'f', 'false', 'no']

export const isTruthy = (s: any): boolean => {
  if (isString(s)) {
    return !falseValues.includes(s.toLowerCase())
  } else {
    return Boolean(s)
  }
}
export const isFalsey = (s: any): boolean => !isTruthy(s)

const queryParam = (key: string): string => {
  const params = new URLSearchParams(window.location.search)
  return params.get(key)
}

export const isDevelopment = (): boolean => {
  return DASHBOARD_ENV === 'development' || queryParam('env') === 'development'
}

export const shouldShowBackButton = () => {
  return false
}

export const isAuthenticated = (store: any): boolean => {
  return !!store.getState().externalConfig.rasaToken
}
