/***************************************************************
 GENERIC Dataset wrapper - this is a non-visual wrapper object
 that knows how to get a dataset from the server
****************************************************************/
import { AjaxWrapper, HttpMethod } from './ajaxWrapper'

export interface DatasetContainer<T> {
  data: T[],
  total: number,
}

export interface DatasetParam {
  param: string,
  value: any,
}

export class Dataset {
  private readonly datasetEndpointUrl: string = '/dataset'
  private readonly publicDatasetEndpointUrl: string = '/public/dataset'

  public loadPublicDataset(
    datasetName: string,
    params?: DatasetParam[],
    pageSize?: number,
    startPage?: number,
  ): Promise<any> {
    return this.loadGlobalDataset(datasetName, params, pageSize, startPage, this.publicDatasetEndpointUrl)
  }

  public loadGlobalDataset(
    datasetName: string,
    params?: DatasetParam[],
    pageSize?: number,
    startPage?: number,
    endpointUrl?: string,
  ): Promise<any> {
    endpointUrl = endpointUrl || this.datasetEndpointUrl
    return this.loadCommunityDataset(datasetName, null, params, pageSize, startPage, null, null, endpointUrl)
  }

  public loadCommunityDataset(
    datasetName: string,
    community?: string,
    params?: DatasetParam[],
    pageSize?: number,
    startPage?: number,
    sortBy?: string,
    sortDir?: string,
    endpointUrl?: string,
  ): Promise<any> {
    if (datasetName === null) {
      return Promise.reject('datasetName must be provided')
    } else {
      endpointUrl = endpointUrl || this.datasetEndpointUrl
      const url: string = AjaxWrapper.getServerUrl() +
                this.getDataUrl(endpointUrl, datasetName, community, pageSize, startPage, sortBy, sortDir, params);
      return AjaxWrapper.ajax(url, HttpMethod.GET, null)
    }
  }

  // override this method to change the Server URL if needed
  protected getDataUrl(
    endpointUrl: string,
    datasetName: string,
    community: string,
    pageSize: number,
    startPage: number,
    sortBy: string,
    sortDir: string,
    params: DatasetParam[],
  ): string {
    // the caller to this function must make sure user is loaded
    const pageParams: DatasetParam[] = (pageSize || startPage) ? [
      {
        param: 'startPage',
        value: startPage || 0,
      },
      {
        param: 'pageSize',
        value: pageSize || 50,
      },
    ] : []

    const sortParams: DatasetParam[] = sortBy ? [
      {
        param: 'sortBy',
        value: sortBy,
      },
      {
        param: 'sortDir',
        value: sortDir || 'desc',
      },
    ] : []

    const allParams: DatasetParam[] = pageParams.concat(sortParams).concat(params || [])
    const queryParams = []
    allParams.forEach((item: DatasetParam) => {
        if (item.value) {
          queryParams.push(`${item.param}=${item.value}`)
        }
    })

    if (community) {
      return encodeURI(`${endpointUrl}/${community}/${datasetName}?${queryParams.join('&')}`)
    } else {
      return encodeURI(`${endpointUrl}/${datasetName}?${queryParams.join('&')}`)
    }
  }
}
