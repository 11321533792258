import React from 'react'
import { CSSTransition } from 'react-transition-group'
import './styles.css'

//tslint:disable-next-line
export const Fade: React.SFC = ({children}) =>
<CSSTransition
  appear={true}
  in={true}
  classNames="fade"
  timeout={500}>
    {children}
  </CSSTransition>
