// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-icon-cls-3 {
  fill:none
}
.category-icon-cls-4 {
  fill:#fff
}
.category-icon-cls-5 {
  fill:#ffe238
}
.category-icon-cls-6 {
  fill:#08151d
}`, "",{"version":3,"sources":["webpack://./src/components/icons/category-icon.css"],"names":[],"mappings":"AAAA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF","sourcesContent":[".category-icon-cls-3 {\n  fill:none\n}\n.category-icon-cls-4 {\n  fill:#fff\n}\n.category-icon-cls-5 {\n  fill:#ffe238\n}\n.category-icon-cls-6 {\n  fill:#08151d\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
