import { NextIcon } from 'components/icons/next'
import React, {Component} from 'react'
import './_styles.scss'

interface SearchTypeProps {
  description?: string,
  icon?: any,
  onSubmit?: any,
  title: string,
}

export class SearchTypeComponent extends Component<SearchTypeProps> {
  constructor(props) {
    super(props)
  }

  public render() {
    return (
      <div className="rct-search-type">
          <div className="icon">{this.props.icon}</div>
          <div className="text-container cursor-pointer" onClick={this.props.onSubmit}>
            <div className="title">{this.props.title}</div>
          </div>
          <div className="submit-btn cursor-pointer" onClick={this.props.onSubmit}>
            <NextIcon />
          </div>
      </div>
    )
  }
}
