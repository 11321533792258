import * as Redux from 'redux'
import { EXTERNAL_CONFIG } from '../rootConstants'
import { EMPTY_CONFIG, ExternalConfig } from './types'

interface ExternalConfigAction extends Redux.Action {
  payload: ExternalConfig,
}

export type Reducer = Redux.Reducer<ExternalConfig, ExternalConfigAction>
export const reducer: Reducer = (state = EMPTY_CONFIG, action: ExternalConfigAction) => {
  switch (action.type) {
    case EXTERNAL_CONFIG:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
